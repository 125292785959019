<template>
	<TableLayout
        :module="module"
        ref="TableLayout"
        :form-initial="form_initial"
        :form-rules="formRules"
        :rights="rights"
        :table-columns="tableColumns"
        v-if="(user && user.rights)"
        initSortField="column_order"
        initSortOrder="asc"
        :filters="filters"
        addButtonName="Add Field"
        sortnField='column_order'>

        <template v-slot:form_items="{formdata}">
    		<el-form-item label="ORKL Name" prop="orkl_name">
                <el-input v-model="formdata.orkl_name" maxlength="100" minlength="2" />
            </el-form-item>
    		<el-form-item label="App Name" prop="app_name">
                <el-input v-model="formdata.app_name" maxlength="100" minlength="2" />
            </el-form-item>

            <el-form-item label="Source Name" prop="reuters_name" :required="isSourceNameRequired(formdata)">
                <el-input v-model="formdata.reuters_name" type="textarea" />
                <div class="input-bottom-tooltip">
                    You can use following variables for dates: [input], [prev], [MtD], [YtD], [1W], [1M], [3M], [6M], [1Y], [3AY]<br>
                    For example: TR.ASKPRICE(Sdate=[prev])
                </div>
            </el-form-item>

            <el-form-item label="Description" prop="description">
                <el-input v-model="formdata.description" />
            </el-form-item>

            <el-form-item label="Get Data" prop="get_data">
                <el-checkbox v-model="formdata.get_data"></el-checkbox>
            </el-form-item>

            <el-form-item label="Field Source" prop="source">
                <el-select v-model="formdata.source">
                    <el-option
                        v-for="item in field_sources"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="Field Type" prop="field_type">
                <el-select v-model="formdata.field_type">
                    <el-option
                        v-for="item in field_types"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="Historical Option" prop="historical_option">
                <el-checkbox v-model="formdata.historical_option"></el-checkbox>
            </el-form-item>

            <el-form-item label="Mapped Dictionary" prop="mapped_dictionary">
                <el-select v-model="formdata.mapped_dictionary" clearable>
                    <el-option
                        v-for="item in field_dicts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="Field Display Format" prop="formatting" v-if="formdata.field_type && formdata.field_type === 'number'">
                <el-select v-model="formdata.formatting" clearable>
                    <el-option
                        v-for="item in field_formats"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Field Decimal Places" prop="decimal_places" v-if="formdata.field_type && formdata.field_type === 'number'">
                <el-input v-model="formdata.decimal_places" type="number" min="0" step="1" />
            </el-form-item>
            <el-form-item label="Column Initial Width" prop="column_width">
                <el-input v-model="formdata.column_width" type="number" min="20" step="1" />
            </el-form-item>
            <el-form-item label="Column Initial Order" prop="column_order">
                <el-input v-model="formdata.column_order" type="number" min="1" step="1" />
            </el-form-item>
            <el-form-item label="Use in Search Filters" prop="filters_use">
                <el-checkbox v-model="formdata.filters_use"></el-checkbox>
            </el-form-item>
        </template>

    </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    name: 'source_riskfree_fields',
    mixins: [tab],
    components: { TableLayout },

    data() {
        return {
        	module: 'sourcesRiskFreeRatesFields',
            form_initial: {
                get_data          : true,
                historical_option : false,
                // minimal_value     : 0,
            },
            filters: {
                reuters_name      : { value: undefined },
                app_name          : { value: undefined },
                orkl_name         : { value: undefined },
                get_data          : { value: undefined, strict: true },
                field_type        : { value: undefined, strict: true },
                source            : { value: undefined, strict: true },
                formatting        : { value: undefined, strict: true },
                historical_option : { value: undefined, strict: true },
                filters_use       : { value: undefined, strict: true },
                description       : { value: undefined },
            },
        }
    },

    computed: {
    	...mapState({
            user          : state => state.app.user,
            field_types   : state => state.app.field_types,
            field_sources : state => state.app.field_sources,
            field_formats : state => state.app.field_formats,
            field_dicts   : state => state.app.field_dicts,
            current       : state => state.sourcesRiskFreeRatesFields.current,
    	}),

        tableColumns(){
            return [
                {
                    field    : 'orkl_name',
                    title    : 'ORKL Name',
                    minWidth : 200,
                    fixed    : 'left',
                    params : {
                        filter : 'input',
                    },
                },{
                    field    : 'app_name',
                    title    : 'App Name',
                    minWidth : 200,
                    params : {
                        filter : 'input',
                    },
                },{
                    field  : 'reuters_name',
                    title  : 'Reuters Name',
                    width  : 200,
                    params : {
                        filter : 'input',
                    },
                },{
                    field  : 'get_data',
                    title  : 'Get Data',
                    width  : 100,
                    align  : 'center',
                    params : {
                        filter : 'boolean',
                        editor : 'checkbox',
                    },
                },{
                    field    : 'field_type',
                    title    : 'Field Type',
                    width    : 120,
                    params : {
                        filter  : 'select',
                        options : this.field_types,
                        editor  : 'select',
                    },
                },{
                    field    : 'source',
                    title    : 'Field Source',
                    width    : 120,
                    params : {
                        filter  : 'select',
                        options : this.field_sources,
                        editor  : 'select',
                    },
                },{
                    field  : 'historical_option',
                    title  : 'Historical Option',
                    width  : 105,
                    align  : 'center',
                    params : {
                        filter : 'boolean',
                        editor : 'checkbox',
                    },
                },{
                    field    : 'formatting',
                    title    : 'Field Display Format',
                    width    : 120,
                    params : {
                        filter  : 'select',
                        editor  : 'select',
                        options : this.field_formats,
                    },
                },{
                    field  : 'decimal_places',
                    title  : 'Field Decimal Places',
                    width  : 100,
                    align  : 'center',
                },{
                    field       : 'column_width',
                    title       : 'Column Initial Width',
                    width       : 100,
                    align       : 'center',
                    params      : {
                        editor      : 'number_input',
                        edit_inline : true,
                    }
                },{
                    field        : 'column_order',
                    title        : 'Column Initial Order',
                    width        : 110,
                    align        : 'center',
                    params       : {
                        editor       : 'number_input',
                        edit_inline  : true,
                    }
                },{
                    field  : 'filters_use',
                    title  : 'Use in Search Filters',
                    width  : 125,
                    align  : 'center',
                    params : {
                        filter : 'boolean',
                        editor : 'checkbox',
                    }
                },{
                    field       : 'description',
                    title       : 'Description',
                    width       : 120,
                    params : {
                        filter      : 'input',
                        editor      : 'input',
                        edit_inline : true,
                    }
                },
            ]
        },

        formRules(){
            let rules = {
                // reuters_name: [
                //     { required: true, message: 'this field is required', trigger: 'blur' },
                //     { min: 2, message: 'this field should have min 2 characters', trigger: 'blur' },
                //     { max: 100, message: 'this field should have max 100 characters', trigger: 'blur' },
                // ],
                app_name: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                    { min: 2, message: 'this field should have min 2 characters', trigger: 'blur' },
                    { max: 100, message: 'this field should have max 100 characters', trigger: 'blur' },
                ],
                orkl_name: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                    { min: 2, message: 'this field should have min 2 characters', trigger: 'blur' },
                    { max: 100, message: 'this field should have max 100 characters', trigger: 'blur' },
                ],
                field_type: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                source: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                owner: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                main_owner: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
            }

            return rules
        },

    	rights(){
    		return {
				view : (this.user && this.user.rights) ? this.user.rights['get-isin-fields']   : false,
                edit : (this.user && this.user.rights) ? this.user.rights['edit-isin-fields']  : false,
                add  : (this.user && this.user.rights) ? this.user.rights['add-isin-fields']   : false,
                del  : (this.user && this.user.rights) ? this.user.rights['delete-isin-fields']: false,
    		}
    	}
    },

    methods: {
        isSourceNameRequired(data) {
            if (data.source && (data.source === 'eikon' || data.source === 'datastream' ))
                return true;
            return false;
        }
    }
}
</script>
